import * as React from "react";
import styled from "styled-components";
import { styles } from "../../lib/styles";
import { NavLink } from "react-router-dom";
import { Icon } from "..";

interface INavigationProps {
  kind?: string;
  customerId: string;
  siteId: string;
  cameraLocationUUID: string;
  public_url: boolean;
  hasVideos?: boolean;
}

interface INavigationState {
  open: boolean;
}

const NavigationWrapper: any = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;

  @media (max-width: ${styles.mobileBreakPoint}) {
    position: relative;
    left: auto;
    margin-left: auto;
    margin-right: inherit;
    transform: none;
  }
`;

const NavLinksWrapper: any = styled.div`
  display: flex;
  > a {
    color: ${styles.fadedTextColor};
    padding-right: 15px;
    text-decoration: none;

    span {
      transition: color 0.2s ease-in-out;
    }
    &.active span,
    &.active svg,
    &:hover span,
    &:hover svg {
      color: ${styles.primaryTextColor};
      fill: ${styles.primaryTextColor};
    }
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NavText = styled.span`
  color: ${styles.fadedTextColor};
  font-weight: 300;
  padding-left: 10px;
  letter-spacing: 1;
  font-size: 14px;

  @media (max-width: ${styles.mobileBreakPoint}) {
    display: none;
  }
`;

class Navigation extends React.Component<INavigationProps, INavigationState> {
  constructor(props: INavigationProps) {
    super(props);
  }

  public render() {
    const {
      customerId,
      cameraLocationUUID,
      siteId,
      public_url,
      hasVideos
    } = this.props;

    return (
      <NavigationWrapper>
        <NavLinksWrapper>
          <NavLink
            to={`/${
              public_url ? "public" : "customer"
            }/${customerId}/site/${siteId}/camera/${cameraLocationUUID}/images`}
          >
            <LinkWrapper>
              <Icon icon="photos" size={25} />
              <NavText>Photos</NavText>
            </LinkWrapper>
          </NavLink>
          {hasVideos && (
            <NavLink
              to={`/${
                public_url ? "public" : "customer"
              }/${customerId}/site/${siteId}/camera/${cameraLocationUUID}/videos`}
            >
              <LinkWrapper>
                <Icon icon="video" size={25} />
                <NavText>Video</NavText>
              </LinkWrapper>
            </NavLink>
          )}
        </NavLinksWrapper>
      </NavigationWrapper>
    );
  }
}

export default Navigation;
