import * as React from "react";
import {
  Route, Router, Switch
} from "react-router-dom";
import {
  CameraLocations,
  Login,
  Logout,
  ImageCompare,
  VideoContainer,
  PublicImageViewer,
  PublicCompareViewer,
  Customers,
  ErrorScreen,
  EditCustomer,
  EditSite,
  CreateCameraLocation,
  CreateCustomer,
  CreateSite,
  Users,
  CreateUser,
  EditUser,
  NotFound,
  CreateVideo,
  LiveImage,
  EditVideo,
  DeletionQueue
} from "./containers";
import { PrivateRoute } from "./components";
import { connect } from "react-redux";
import { IStore } from "./types/store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import editCameraLocation from "./containers/admin/edit-camera-location";
import withTracker from "./lib/withTracker";
import { checkLoggedIn } from "./store/auth/auth.actions";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import ImageViewer from "./containers/protected/image-viewer";

interface IStateProps {
  authenticated: boolean;
  admin: boolean;
}

interface IDispatchProps {
  checkLoggedIn: () => Promise<void>;
}

interface IProps {
  history: any;
}
type Props = IStateProps & IProps & IDispatchProps;

const AppRouter = ({
  authenticated, history, admin, checkLoggedIn
}: Props) => {
  React.useEffect(() => {
    checkLoggedIn();
  }, [checkLoggedIn]);

  const adminRoutes = [
    {
      component: CameraLocations,
      exact: true,
      path: "/admin/cameras/"
    },
    {
      component: Customers,
      exact: true,
      path: "/admin/customers/"
    },
    {
      component: EditCustomer,
      exact: true,
      path: "/admin/customer/:customerId"
    },
    {
      component: CreateCustomer,
      exact: true,
      path: "/admin/new-customer"
    },
    {
      component: CreateSite,
      exact: true,
      path: "/admin/customer/:customerId/new-site"
    },
    {
      component: EditSite,
      exact: true,
      path: "/admin/customer/:customerId/site/:siteId"
    },
  
    {
      component: CreateCameraLocation,
      exact: true,
      path: "/admin/customer/:customerId/site/:siteId/new-camera"
    },
    {
      component: Users,
      exact: true,
      path: "/admin/users/"
    },
    {
      component: CreateUser,
      exact: true,
      path: "/admin/new-user"
    },
    {
      component: EditUser,
      exact: true,
      path: "/admin/user/:userId/"
    },
    {
      component: editCameraLocation,
      exact: true,
      path:
          "/admin/customer/:customerId/site/:siteId/camera/:cameraLocationUUID"
    },
    {
      component: CreateVideo,
      exact: true,
      path:
          "/admin/customer/:customerId/site/:siteId/camera/:cameraLocationUUID/new-video"
    },
    {
      component: EditVideo,
      exact: true,
      path:
          "/admin/customer/:customerId/site/:siteId/camera/:cameraLocationUUID/video/:videoId"
    },
    {
      component: DeletionQueue,
      exact: true,
      path: "/admin/deletion-queue"
    }
  ];

  const customerRoutes = [
    {
      component: withTracker(ImageViewer),
      exact: true,
      path:
          "/customer/:customerId/site/:siteId/camera/:cameraLocationUUID/images"
    },
    {
      component: withTracker(ImageCompare),
      exact: true,
      path:
          "/customer/:customerId/site/:siteId/camera/:cameraLocationUUID/compare"
    },
    {
      component: withTracker(VideoContainer),
      exact: true,
      path:
          "/customer/:customerId/site/:siteId/camera/:cameraLocationUUID/videos"
    },
    {
      component: withTracker(LiveImage),
      path: "/customer/:customerId/site/:siteId/camera/:cameraLocationUUID/live-image"
    },
    {
      component: withTracker(LiveImage),
      path: "/admin/customer/:customerId/site/:siteId/camera/:cameraLocationUUID/live-image"
    }
  ];

  const unProtectedRoutes = [
    {
      path: "/logout",
      component: Logout
    },
    {
      path: "/",
      component: Login
    },
    {
      path: "/error",
      component: ErrorScreen
    },
    {
      component: withTracker(LiveImage),
      path: "/public/:customerId/site/:siteId/camera/:cameraLocationUUID/live-image"
    },
    {
      component: withTracker(PublicImageViewer),
      path: "/public/:customerId/site/:siteId/camera/:cameraLocationUUID/images"
    },
    {
      component: withTracker(PublicCompareViewer),
      path:
          "/public/:customerId/site/:siteId/camera/:cameraLocationUUID/compare"
    },
    {
      component: withTracker(VideoContainer),
      exact: true,
      path: "/public/:customerId/site/:siteId/camera/:cameraLocationUUID/videos"
    }
  ];

  return (
    <Router history={history}>
      <div>
        <ToastContainer />
        <Switch>
          {/* Unprotected Routes  */}
          {unProtectedRoutes.map((route, index) => {
            return (
              <Route
                key={`unprotected-route-${index}`}
                exact={true}
                path={route.path}
                component={route.component}
              />
            );
          })}

          {/* Protected Customer Routes */}
          {customerRoutes.map((route, index) => {
            return (
              <PrivateRoute
                isAuthenticated={authenticated || false}
                redirect="/logout"
                key={`customer-route-${index}`}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
          {/* Admin routes */}
          {adminRoutes.map((route, index) => {
            return (
              <PrivateRoute
                key={`admin-route-${index}`}
                isAuthenticated={authenticated && admin || false}
                isAdmin={admin}
                redirect="/logout"
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          })}
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

const mapStateToProps = (state: IStore): IStateProps => {
  return {
    admin: state.auth.admin,
    authenticated: state.auth.authenticated
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return { checkLoggedIn: () => dispatch(checkLoggedIn()) };
};

export default connect<IStateProps, IDispatchProps, IProps>(mapStateToProps, mapDispatchToProps)(AppRouter);